/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Router, BrowserRouter } from 'react-router-dom';

import { StyleGlobal } from 'styles/GlobalStyles';

import { HomePage } from '../HomePage/Loadable';
import { SignUpPage } from '../SignUpPage/Loadable';
import { LoginPage, SelectLoginOptionPage } from '../LoginPage/Loadable';
import { TourDetailPage } from '../TourDetailPage/Loadable';
import { SearchPage } from '../SearchPage/Loadable';
import { TourListByCityPage } from '../TourListByCityPage/Loadable';
import { CityChangePage } from '../CityChangePage/Loadable';
import { NotFoundPage } from '../../components/NotFoundPage/Loadable';
import {
  UserReviewPage,
  AllPassUnwrittenReviewPage,
} from '../UserReviewPage/Loadable';
import { UserReviewConfirmPage } from '../UserReviewConfirmPage/Loadable';
import { TourPurchasedListPage } from '../PurchasedTourPage/Loadable';
import { PointPage } from '../Point/Loadable';
import { UserCouponPage } from '../UserCouponPage/Loadable';
import { UserPaymentHistoryPage } from '../UserPaymentHistoryPage/Loadable';
import { UserInformationPage } from '../UserInformationPage/Loadable';
import { PaymentDetailPage } from '../PaymentDetailPage/Loadable';
import { UserCardPage } from '../UserCardPage/Loadable';
import { FindPasswordPage } from '../FindPasswordPage/Loadable';
import { ChangePasswordPage } from '../ChangePasswordPage/Loadable';
import { PRIMARY_COLOR, routes, SECONDARY_COLOR } from 'utils/const';
import {
  createMuiTheme,
  ThemeProvider as MtThemeProvider,
} from '@material-ui/core';
import { MyPage } from '../MyPage';
import scTheme from 'constants/theme';
import history from 'constants/history';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  AudioContext,
  GlobalAudio,
  useAudioControl,
} from 'app/components/Common/Audio';
import { TermsOfServicePage } from '../TermsOfService/Loadable';
import { CheckoutPage, PayConfirmationPage } from '../CheckoutPage/Loadable';
import { CheckoutSuccessPage } from '../CheckoutSuccessPage/Loadable';
import { useUser } from 'utils/hooks/useUser';
import { EditReviewPage, NewReviewPage } from '../WriteReviewPage/Loadable';
import useAcquisition from 'utils/hooks/useAcquisition';
import { useEffectOnce } from 'react-use';
import { FooterContext } from 'app/components/Main/Footer';
import { DestinationsPage } from '../Destinations/Loadable';
import { AppDownloadPage } from '../AppDownload/Loadable';

export const mtLightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: primary(),
    secondary: secondary(),
  },
});

export function App() {
  const audioRef = React.useRef<any>();
  const audioState = useAudioControl(audioRef);
  const [footerRef, setFooterRef] = React.useState();
  useUser();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s"
        defaultTitle="투어라이브 | 가이드 없이 즐기는 편리한 셀프투어"
        // htmlAttributes={{ lang: i18n.language }}
      ></Helmet>

      <FooterContext.Provider value={{ ref: footerRef, setRef: setFooterRef }}>
        <AudioContext.Provider value={{ reference: audioRef, ...audioState }}>
          <GlobalAudio audioRef={audioRef} {...audioState} />

          <Router history={history}>
            <MtThemeProvider theme={mtLightTheme}>
              <ScThemeProvider theme={scTheme}>
                <StyleGlobal />
                <Routes />
              </ScThemeProvider>
            </MtThemeProvider>
          </Router>
        </AudioContext.Provider>
      </FooterContext.Provider>
    </BrowserRouter>
  );
}

function Routes() {
  useAcquisition();
  useEffectOnce(() => {
    (window as any).dataLayer.push({ ecommerce: null });
  });
  const currentUrl = window.location.href;
  const pathName = window.location.pathname;
  let newPathName = '';
  let tourFullUrl = '';
  console.log('pathName: ' + pathName);

  if (pathName.startsWith('/tour/')) {
    const pathParts = pathName.split('/');
    if (pathParts.length > 3 && pathParts[0] === '') {
      const newPathParts = pathParts.slice(0, 3);
      newPathName = newPathParts.join('/');
    } else {
      newPathName = pathName;
    }
    tourFullUrl = `https://${window.location.hostname}${newPathName}`;
    console.log(`tourFullUrl: ${tourFullUrl}`);
  }
  React.useEffect(() => {
    const ogUrlMeta = document.querySelector(
      'meta[data-id="og:url"]',
    ) as HTMLMetaElement | null;
    if (ogUrlMeta) {
      if (pathName.startsWith('/tour/')) {
        ogUrlMeta.content = tourFullUrl;
      } else if(pathName === '/') {
        ogUrlMeta.content = `https://${window.location.hostname}`;
      } else {
        ogUrlMeta.content = currentUrl;
      }
    }

    const canonicalLink = document.querySelector(
      'link[data-id="canonical-link"]',
    ) as HTMLLinkElement | null;
    if (canonicalLink) {
      if (pathName.startsWith('/tour/')) {
        canonicalLink.href = tourFullUrl;
      } else if (pathName === '/'){
        canonicalLink.href = `https://${window.location.hostname}`;
      } else {
        canonicalLink.href = currentUrl;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  return (
    <>
      <div id="wrapLeftArea">
        <div className="contentBox">
          <div className="logoBox">
            <img src={require('assets/images/logo.png')} alt="tourlive-logo" />
          </div>
          <div className="titleBox">
            <h2 style={{ lineHeight: '40px' }}>
              가이드 없이 즐기는 <br></br>편리한 셀프투어
            </h2>
            <p>투어라이브앱에서 여행 맛집지도를 받아보세요</p>
          </div>
          <div className="qrBox">
            <img src={require('assets/images/QRCodeImg.png')} alt="QR-Code" />
          </div>
          <div className="textBox">
            <p className="f14 t2">앱 다운로드</p>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path={routes.CITY_CHANGE}
          exact={true}
          component={CityChangePage}
        />
        <Route
          path={routes.TOUR_LIST_CITY}
          exact={true}
          component={TourListByCityPage}
        />
        <Route path={routes.SEARCH} exact={true} component={SearchPage} />
        <Route path={routes.CHECKOUT} exact={true} component={CheckoutPage} />
        <Route
          path={routes.CHECKOUT_CONFIMATION}
          component={PayConfirmationPage}
        />
        <Route path={routes.CHECKOUT_SUCCESS} component={CheckoutSuccessPage} />
        <Route
          exact={true}
          path={routes.TOUR_DETAIL}
          component={TourDetailPage}
        />

        <Route path={routes.TOUR_DETAIL_WITH_SLUG} component={TourDetailPage} />
        <Route path={routes.EMAIL_SIGN_UP} component={SignUpPage} />
        <Route path={routes.USER_LOGIN} component={LoginPage} exact={true} />
        <Route
          path={routes.SELECT_LOGIN_OPTION}
          component={SelectLoginOptionPage}
        />
        <Route path={routes.MY_PAGE} component={MyPage} />
        <Route
          path={routes.USER_PURCHASED_TOUR}
          component={TourPurchasedListPage}
        />
        <Route path={routes.POINTS} component={PointPage} />
        <Route path={routes.DESTINATIONS} component={DestinationsPage} />
        <Route path={routes.APP_DOWNLOAD} component={AppDownloadPage} />
        <Route
          path={routes.USER_REVIEW_LIST}
          component={UserReviewPage}
          exact={true}
        />
        <Route
          path={routes.USER_ALL_PASS_REVIEWS}
          component={AllPassUnwrittenReviewPage}
        />
        <Route path={routes.USER_REVIEW_FORM_NEW} component={NewReviewPage} />
        <Route path={routes.USER_REVIEW_FORM_EDIT} component={EditReviewPage} />
        <Route
          path={routes.USER_REVIEW_DETAIL}
          component={UserReviewConfirmPage}
          exact={true}
        />
        <Route path={routes.USER_COUPONS} component={UserCouponPage} />
        <Route
          exact={true}
          path={routes.USER_PURCHASES}
          component={UserPaymentHistoryPage}
        />
        <Route
          path={routes.USER_PURCHASE_DETAIL}
          component={PaymentDetailPage}
        />
        <Route path={routes.USER_PROFILE} component={UserInformationPage} />
        <Route path={routes.USER_CARD} component={UserCardPage} />
        <Route
          exact={true}
          path={routes.FIND_PASSWORD}
          component={FindPasswordPage}
        />
        <Route path={routes.CHANGE_PASSWORD} component={ChangePasswordPage} />
        <Route path={routes.TERMS_OF_SERVICE} component={TermsOfServicePage} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
}

function primary() {
  return {
    50: PRIMARY_COLOR,
    100: PRIMARY_COLOR,
    200: PRIMARY_COLOR,
    300: PRIMARY_COLOR,
    400: PRIMARY_COLOR,
    500: PRIMARY_COLOR,
    600: PRIMARY_COLOR,
    700: PRIMARY_COLOR,
    800: PRIMARY_COLOR,
    900: PRIMARY_COLOR,
    A100: PRIMARY_COLOR,
    A200: PRIMARY_COLOR,
    A400: PRIMARY_COLOR,
    A700: PRIMARY_COLOR,
  };
}

function secondary() {
  return {
    50: SECONDARY_COLOR,
    100: SECONDARY_COLOR,
    200: SECONDARY_COLOR,
    300: SECONDARY_COLOR,
    400: SECONDARY_COLOR,
    500: SECONDARY_COLOR,
    600: SECONDARY_COLOR,
    700: SECONDARY_COLOR,
    800: SECONDARY_COLOR,
    900: SECONDARY_COLOR,
    A100: SECONDARY_COLOR,
    A200: SECONDARY_COLOR,
    A400: SECONDARY_COLOR,
    A700: SECONDARY_COLOR,
  };
}
